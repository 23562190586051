
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import FileAttach from '@/components/shared/utils/FileAttach.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'

export default defineComponent({
  components: {
    TmDropdownItem,
    TmButton,
    TmDropdown,
    TmModal,
    FileAttach,
  },
  props: {
    logo: {
      type: Boolean,
    },
  },
  emits: ['updateLogo', 'deleteLogo'],
  setup(props, context) {
    const cropLogoModal = ref()
    const showCropLogoModal = () => {
      cropLogoModal.value.openModal()
    }
    const saveCropLogo = (hideModal: () => void) => {
      hideModal()
      context.emit('updateLogo')
    }

    return {
      cropLogoModal,
      showCropLogoModal,
      saveCropLogo,
    }
  },
})
