
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import TmChip from '@/components/shared/TmChip.vue'
import TmButton from '@/components/shared/TmButton.vue'
import LogoUploadButton from '@/components/pages/admin/workspace/settings/LogoUploadButton.vue'
import useAccountOpenModal from '@/compositions/account/useAccountOpenModal'

export default defineComponent({
  name: 'WorkspaceGeneral',
  components: {
    TmButton,
    TmChip,
    PageContent,
    LogoUploadButton,
    InfoCard,
    InfoCardRow,
  },
  props: {
    menuTitle: {
      type: String,
    },
  },
  setup() {
    const {
      openResetPasswordModal,
      openEditNameModal,
      open2FADisableModal,
      open2FAModal,
      openWorkspaceDeleteModal,
      openWhiteListModal,
    } = useAccountOpenModal()
    const securityState = ref({
      twoFactorEnabled: false,
      whitelistEnabled: false,
    })
    const logo = ref(false)
    const uploadLogo = () => {
      logo.value = true
    }
    const deleteLogo = () => {
      logo.value = false
    }

    const handle2FADisable = () => {
      open2FADisableModal(() => {
        securityState.value.twoFactorEnabled = !securityState.value.twoFactorEnabled
      })
    }

    const handle2FA = () => {
      open2FAModal(() => {
        securityState.value.twoFactorEnabled = !securityState.value.twoFactorEnabled
      })
    }

    const handleWhiteList = () => {
      openWhiteListModal(() => {
        securityState.value.whitelistEnabled = !securityState.value.whitelistEnabled
      })
    }

    return {
      openWorkspaceDeleteModal,
      openWhiteListModal,
      openResetPasswordModal,
      openEditNameModal,
      securityState,
      logo,
      uploadLogo,
      deleteLogo,
      handle2FADisable,
      handle2FA,
      handleWhiteList,
    }
  },
})
